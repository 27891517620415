.layout {
    min-height: 100vh;
    background-color: #fff;
}

.header {
    
    background-color: #fff;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        max-width: 1140px;
    }

    &__aditional {
        display: flex;
        align-items: center;
    }
}

.lang_selector {
    margin-right: 20px;
    cursor: pointer;

    &__wrap {
        display: flex;
        align-items: center;
    }

    .avatar {
        margin-right: 8px;
    }
}