.icon {
  font-size: 24px;
}

.button {
  float: right;
  margin: -50px 0 10px 0;
}

.input_number {
  width: 100%;
}

.exportBtn {
    display: flex;
    align-items: center;
    color: #B3A063;
    border:1px solid #B3A063;
    background-color: transparent;
    font-size: 14px;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
    
    &:hover {
        color: #fff;
        background-color: #B3A063;
    }
}