.filters {
    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 12px;
        min-height: 32px;

        .tag {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

.bigBtn{
    color: #B3A063;
    border:1px solid #B3A063;
    background-color: transparent;
    &:hover {
        color: #fff !important;
        background-color: #B3A063 !important;
    }
}

.filters-name {
    color: #000;
    margin-right: 10px;
    font-size: 12px;
    
    &__count {
        color: #6C757D;
    }
}

.tag {
    background: transparent;
}
.resetTag {
    cursor: pointer;
}
.timezoneSelect {
    margin-top: 28px;
    z-index: 100;
}

.title {
    font-size: 16px;
    line-height: 40px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 12px;
    border-bottom: 1px solid #0D6EFD;
    margin-bottom: 24px;

    &.fromTo {
      border-bottom: 1px solid #c43649;
    }
  }

.timezonePickerContainer{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.timeRangeWrapper {
    margin-top: 10px; 
    margin-bottom: 10px;
}
.hide-selection-column {
    display: none;
  }
.buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: max-content;
    padding-bottom: 20px;
    gap:20px;
}

.calendar{
    width: 100%;
    margin: 2px 0 0 0 !important;
    height: 34px;
}
.main_container{
    width: max-content;
    margin: 0 auto;
}

.ant-table-row{
    height: max-content;
}

td.ant-table-cell {
    width: fit-content !important;
}

.table-top-space{
    justify-content: center;
}

.tableSummary{
    font-weight: bold;
    background-color: #f1f1f1;
}