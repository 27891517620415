[data-supertokens="button"] {
    background-color: #252571;
    border: 0px;
    width: 30%;
    margin: 0 auto;
}

.heder_title {
    margin: 48px 0;
    font-size: 32px;
    color: #000;
    text-align: center;
    font-weight: 700;
}

.main_container {
    max-width: 1140px;
    margin: 0 auto;
}

.ant-menu-horizontal {
    border-bottom: none;
}
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected {
    color: #B3A063;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected::after, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected::after {
    border-bottom-color: #B3A063;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item:hover::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu:hover::after, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu:hover::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-active::after, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-active::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-active::after, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-active::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-open::after, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-open::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-open::after, 
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-open::after {
    border-bottom-color: #B3A063;
}
// BUTTONS
.ant-btn-primary {
    background-color: #B3A063;
    &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: #b7ab83;
    }

    &:not(:disabled):not(.ant-btn-disabled):active {
        background-color: #b7ab83;
    }
}
.ant-btn-link {
    color: #b7ab83;
}
.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: #b6af98;
}
.filter-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 166px;
    height: 40px;
    background-color: #54595E;
    border-color: #54595E;
    color: #FFF;
    margin-left: 0;
    margin-right: auto;
    padding: 8px 16px;
    border-radius: 8px 8px 0px 0px;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #B3A063;
    border-color: #B3A063;
}

// TABLES
.table-top-space {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
    // padding: 9px 0 ;
    // margin-bottom: -41px;
}

.ant-btn-primary.ant-btn-background-ghost {
    color: #B3A063;
    border-color: #B3A063;
    &:not(:disabled):not(.ant-btn-disabled):hover {
        color: #b7ab83;
        border-color: #b7ab83;
    }

    &:not(:disabled):not(.ant-btn-disabled):active {
        color: #b7ab83;
        border-color: #b7ab83;
    }

}

td.ant-table-selection-column {
    display: none;
}

.ant-picker {
    margin: 0 10px;
    &:hover {
        border-color: #B3A063;
    }
}
.ant-picker-focused.ant-picker {
    border-color: #B3A063;
}

.ant-picker-range .ant-picker-active-bar {
    background: #B3A063;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #B3A063;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #B3A063;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: rgba(#B3A063, 0.2);
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: rgba(#B3A063, 0.2);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel>:not(.ant-picker-date-panel) 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel>:not(.ant-picker-date-panel) 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: rgba(#B3A063, 0.2);
}


.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed #B3A063;
    border-bottom: 1px dashed #B3A063;
}

tr>.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after, 
tr>.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-inline-start: 1px dashed #B3A063;
}

tr>.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after, 
tr>.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after, 
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-inline-end: 1px dashed #B3A063;
}


.ant-tabs {
    .ant-tabs-nav {
        padding-left: 150px;
        border-bottom: 2px solid #B3A063;
        margin-bottom: 0;
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                border: none;
                background-color: rgba(179, 160, 99, 0.10);
                .ant-tabs-tab-btn {
                    color: #000;
                }
        
                &.ant-tabs-tab-active {
                    background-color: #B3A063;
        
                    .ant-tabs-tab-btn {
                        color: #fff;
                    }
                }
            }
        }
        
    }
}


.ant-table {
    .ant-table-thead {
        th {
            &:before {
                display: none;
            }
        }
        tr:first-child {
            .ant-table-cell{
                &:first-child,
                &:last-child {
                    border-start-start-radius: 0;
                    border-start-end-radius: 0;
                }
            }
        }
        th.ant-table-column-sort,
        th.ant-table-column-has-sorters {
            background-color: #B3A063;
            color: #fff;
            &:hover {
                background-color: #B3A063;
            }
        }
        .ant-table-cell {
            background-color: #B3A063;
            color: #fff;
            
        }
    }
}


.ant-pagination .ant-pagination-item-active {
    background-color: #B3A063;
    border-color: #B3A063;

    &:hover {
        background-color: #B3A063;
        border-color: #B3A063;
        a {
            color: #fff;
        }
    }

    a {
        color: #fff;
    }
}

.table-footer {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    height: 64px;
    border-top: 2px solid #B3A063;
    border-bottom: 2px solid #B3A063;
    margin-top: -64px;
}


// forms
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #B3A063;
    border-color: #B3A063;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #B3A063;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, 
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #B3A063;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
    &:after {
        background-color: #B3A063;
    }
}


.ant-input-affix-wrapper {
    &:hover,
    &:focus,  
    &:focus-within {
        border-color: #B3A063;
    }
}
    





