.main-filter {
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  //border: 1px solid #54595E;

  .title {
    font-size: 16px;
    line-height: 40px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 12px;
    border-bottom: 1px solid #0D6EFD;
    &.operators {
      border-bottom: 1px solid #0DCAF0;
    }
    
    &.metrics {
      border-bottom: 1px solid #20C997;
    }

     &.countries {
      border-bottom: 1px solid #c9b820;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    min-height: 25px;
    font-size: 14px;
    color: #000;
  }

  .checkboxGroup {
    label {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 14px;
      color: #000;
    }
  }

  .input {
    margin: 12px 0;
  }

  &__col {
   width: 270px;
   padding: 12px 10px;
  }
}

.filterItemsWrap {
  max-height: 200px;
  overflow: auto;
  padding: 0px 0px;
}

.selectionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:32px;
}

.radioFilterItemsWrap {
  max-height: 200px;
  overflow: auto;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap:10px;
}
.ant_tag {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.invertButton {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 10px;
  color: #000;
}

.errorMsg {
  margin:6px 0px;
  height:16px;
  span{
    font-size: 12px;
  }
}